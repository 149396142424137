<!-- 讲解视频 -->
<template>
  <div class="bg" style="height: 14.45rem">
    <el-row>
      <el-col :span="24">
        <div class="df f-w colpd box bg-white">
          <div
            class="item"
            :class="index == idx ? 'choose' : ''"
            v-for="(item, idx) in videoList"
            :key="item.id"
            @click="
              index = idx;
              activeFigure = item;
            "
          >
            <img :src="item.cover" alt="" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="15">
        <div class="write colpd bg-white mt5 df jc-sbn">
          <div class="title">音频列表</div>
          <el-button type="warning" icon="el-icon-upload2" size="mini" @click="uploadFile">上传音频</el-button>
        </div>
        <div class="info colpd bg-white">
          <el-table :data="tableData" highlight-current-row @current-change="handleCurrentChange" style="width: 100%" height="354">
            <el-table-column align="center" show-overflow-tooltip prop="id" label="音频id" width="70"> </el-table-column>
            <el-table-column align="center" show-overflow-tooltip prop="title" label="音频名称" width="160"></el-table-column>
            <el-table-column align="center" show-overflow-tooltip prop="timbre_name" label="声音名称"></el-table-column>
            <el-table-column align="center" show-overflow-tooltip prop="create_time" label="创建时间" width="120"></el-table-column>
            <el-table-column align="center" show-overflow-tooltip prop="times" label="时长"></el-table-column>
            <el-table-column align="center" fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button @click.stop="delItem(scope.row)" type="danger" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination layout="prev, pager, next" :current-page="page" :total="total * 10" @current-change="currentChange"></el-pagination>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="voxBox mt5 ml5">
          <div class="write colpd bg-white mt5">
            <div class="title">
              <el-input type="text" placeholder="请输入互动关键词" v-model="gjc"> </el-input>
            </div>
            <div class="mt5">
              <el-input type="textarea" maxlength="300" resize="none" show-word-limit placeholder="请输入文案话术进行合成" v-model="wenan"> </el-input>
            </div>
          </div>
          <div class="btns bg-white">
            <div class="df jc-sbn f-w">
              <div
                class="botton df"
                :class="btnindex == idx ? 'choose' : ''"
                v-for="(i, idx) in timbreList"
                :key="idx"
                @click="
                  timbre = i;
                  btnindex = idx;
                "
              >
                <img :src="i.logo" alt="" />
                <div>
                  <div>{{ i.name }}</div>
                  <div>{{ i.text }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom bg-white">
            <div class="df jc-sbn">
              <span class="demonstration">语速：{{ pace }}</span>
              <div style="width: 70%">
                <el-slider v-model="pace" :min="-5" :max="5" show-tooltip></el-slider>
              </div>
            </div>
            <div class="df jc-sbn">
              <span class="demonstration">音调：{{ tone }}</span>
              <div style="width: 70%">
                <el-slider v-model="tone" :min="-5" :max="5"></el-slider>
              </div>
            </div>
            <div class="df jc-sbn">
              <el-button type="primary" @click="playMp3">试听</el-button>
              <el-button type="primary" @click="craftAudio">合成音频</el-button>
              <el-button type="primary" @click="craftVideo">合成视频</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- <audio style="opacity: 0;" id="myaudio" :src="audioSrc" loop="loop" preload="preload" muted="muted" /> -->
    <input type="file" ref="fileInput" accept="audio/*" @change="handleFileUpload" style="display: none" />
  </div>
</template>
<script>
import { getFigure, craftVideo } from "@/api/video.js";
import { gettimbre, getsound, craftAudio, del_sound, upload_mp3, submits_sound } from "@/api/audio.js";
export default {
  data() {
    return {
      action_url: "",
      videoList: [],
      tableData: [],
      timbreList: [],
      dialogVisible: false,
      player: null, // 用来存储当前 video
      wenan: "",
      pace: 0,
      tone: 0,
      timbre: "",
      index: null,
      btnindex: null,
      activeAudio: "",
      activeFigure: "",
      gjc: "",
      total: 1,
      page: 1,
    };
  },
  mounted() {
    // 渲染视频
    this.init();
  },
  methods: {
    init() {
      this.getFigure();
      this.gettimbre();
      this.getsound();
    },
    getFigure() {
      getFigure().then((res) => {
        if (res.code == "200") {
          this.videoList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    gettimbre() {
      gettimbre().then((res) => {
        if (res.code == "200") {
          // this.$message.success(res.msg);
          this.timbreList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadFile() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const loading = this.$loading({
        lock: true,
        text: "上传音频中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append("file", file);
      formData.append("token", localStorage.getItem("token"));

      upload_mp3(formData).then((res) => {
        if (res.code == "200") {
          submits_sound({
            url: res.data.url,
          }).then((res) => {
            loading.close();
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getsound()
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getsound() {
      getsound({
        item: 10,
        page: this.page,
      }).then((res) => {
        if (res.code == "200") {
          this.total = res.data.page_num;
          this.tableData = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    delItem(i) {
      del_sound({ sound_id: i.id }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.getsound();
      });
    },
    playMp3() {
      if (!this.timbre.id) {
        this.$message.warning("请选择要试听的音色！");
        return;
      }
      let mp3 = new Audio(this.timbre.url); // 创建音频对象
      mp3.play(); // 播放
    },
    craftAudio() {
      if (!this.wenan) {
        this.$message.warning("请输入文案进行合成音频！");
        return;
      }
      if (!this.timbre.id) {
        this.$message.warning("请选择音色进行合成音频！");
        return;
      }

      craftAudio({
        timbre_id: this.timbre.id,
        text: this.wenan,
        speech_rate: this.pace * 100,
        pitch_rate: this.tone * 100,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getsound();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    craftVideo() {
      if (!this.activeAudio) {
        this.$message.warning("请选择已生成的音频进行合成视频！");
        return;
      }
      if (!this.activeFigure.id) {
        this.$message.warning("请选择主播形象进行合成视频！");
        return;
      }
      if (!this.gjc) {
        this.$message.warning("请填写需要匹配的关键词！");
        return;
      }
      craftVideo({
        image_id: this.activeFigure.id,
        sound_id: this.activeAudio,
        keyword: this.gjc,
        type: 2,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(e) {
      this.activeAudio = e.id;
    },
    currentChange(e) {
      this.page = e;
      this.getsound();
    },
    test() {
      //
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-textarea__inner,
/deep/.el-input__inner {
  border: none !important;
  background: #f1f4f9 !important;
}

/deep/.el-textarea__inner {
  height: 1.4rem !important;
}

.box {
  height: 5.58rem;
  overflow: scroll;
}

.item {
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}

.choose {
  border: 2px solid #409eff;
  border-radius: 5px;
}

.item img {
  /* width: 15vw;
  height:42vh; */
  width: 3.36rem;
  height: 5.1rem;
  border-radius: 5px;
}

.item:last-child,
.item:nth-child(5n) {
  margin-right: 0;
}
.item:nth-child(-n + 5) {
  margin-bottom: 0;
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.voxBox {
  .btns {
    padding: 0.3rem .3rem 0;
    // background: #f1f4f9;
    height: 3.2rem;
    overflow: scroll;
    margin-bottom: 5px;

    .botton {
      padding: 10px !important;
      margin-bottom: 10px;
      margin-left: 0 !important;
      width: 48%;
      height: 1.2rem;
      text-align: left;
      font-size: 0.26rem;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    img {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50px;
      margin-right: 7px;
    }
  }

  .bottom {
    padding: 0.3rem 0.4rem;
  }
}
::v-deep {
  .el-table--striped .el-table__body tr.el-table__row--striped.current-row td.el-table__cell {
    background-color: #618fc1;
  }
}
// 滚动条的宽度
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}
// 滚动条的滑块
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>
