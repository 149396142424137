<template>
  <div class="bg">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="讲解视频" name="first">
        <el-row>
          <el-col :span="24">
            <div class="info colpd bg-white">
              <el-table :data="tableData" highlight-current-row stripe @selection-change="handleSelectionChange" height="621">
                <el-table-column align="center" show-overflow-tooltip type="selection"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="id" label="视频id"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="sound_id" label="音频id"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="title" label="视频标题" width="160"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="video_name" label="形象名称"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="timbre_name" label="声音名称"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="create_time" label="创建时间" width="120"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="name" label="状态">
                  <template slot-scope="scope">
                    {{ scope.row.statusa == 1 ? "队列中" : scope.row.statusa == 2 ? "暂停中" : scope.row.statusa == 3 ? "已合成" : "合成失败" }}
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="times" label="时长"></el-table-column>
                <el-table-column align="center" fixed="right" label="操作" width="145">
                  <template slot-scope="scope">
                    <el-button @click.stop="handleCurrentChange(scope.row)" type="primary" size="small">查看</el-button>
                    <el-button @click.stop="delItem(scope.row)" type="danger" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <div> -->
              <el-pagination background layout="prev, pager, next" :total="total * 10" @current-change="currentChange"></el-pagination>
              <!-- </div> -->
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="互动视频" name="second">
        <el-row>
          <el-col :span="24">
            <div class="info colpd bg-white">
              <el-table :data="tableData" highlight-current-row height="621" @selection-change="handleSelectionChange">
                <el-table-column align="center" show-overflow-tooltip type="selection"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="id" label="视频id"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="sound_id" label="音频id"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="keyword" label="关键词"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="title" label="视频标题" width="150"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="video_name" label="形象名称"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="timbre_name" label="声音名称"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="create_time" label="创建时间"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="name" label="状态">
                  <template slot-scope="scope">
                    {{ scope.row.statusa == 1 ? "队列中" : scope.row.statusa == 2 ? "暂停中" : scope.row.statusa == 3 ? "已合成" : "合成失败" }}
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="times" label="时长"></el-table-column>
                <el-table-column align="center" fixed="right" label="操作" width="145">
                  <template slot-scope="scope">
                    <el-button @click.stop="handleCurrentChange(scope.row)" type="primary" size="small">查看</el-button>
                    <el-button @click.stop="delItem(scope.row)" type="danger" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <div> -->
              <el-pagination background layout="prev, pager, next" :current-page="page" :total="total * 10" @current-change="currentChange"></el-pagination>
              <!-- </div> -->
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <div class="btn">
      <el-button size="mini" type="primary" icon="el-icon-refresh-right" @click="reloadList">刷新</el-button>
      <el-button size="mini" type="info" icon="el-icon-folder-opened" @click="openFile">打开文件保存目录</el-button>
      <el-button size="mini" type="warning" icon="el-icon-download" @click="downLoadSection">保存选中视频</el-button>
      <el-button size="mini" type="success" icon="el-icon-download" @click="downLoad">保存全部视频</el-button>
      <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="dialogVisible = true">删除选中视频</el-button> -->
    </div>

    <el-dialog :title="activeInfo.title" :show-close="false" :visible.sync="dialogVisible" width="50%">
      <video id="vplayer_start" ref="vplayer_start" width="100%" height="350px" autoplay :src="activeInfo.url"></video>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getVideo, del_video } from "@/api/video.js";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";

export default {
  data() {
    return {
      activeName: "first",
      dialogVisible: false,
      checkedInfo: {},
      tableData: [],
      activeInfo: {},
      chooseItem: [],
      player: null, // 用来存储当前 video
      page: 1,
      total: 1,
    };
  },
  mounted() {
    // this.downLoad();
    this.getVideo();
    // this.reLoop();
  },
  methods: {
    reLoop() {
      let timer = setInterval(() => {
        let params = {
          item: 10,
          page: this.page,
          type: this.activeName == "first" ? "1" : "2",
        };
        getVideo(params).then((res) => {
          if (res.code == "200") {
            this.total = res.data.page_num;
            this.tableData = res.data.list;
          }
        });
      }, 5000);
      console.log(1, timer);

      this.$store.commit("setmInterval", timer);
    },
    currentChange(e) {
      this.page = e;
      this.getVideo();
    },
    downLoad() {
      getVideo().then((res) => {
        if (res.code == "200") {
          // this.$store.commit("saveSrcList", res.data.list);
          this.$ipc.invoke(ipcApiRoute.downloadVideo, res.data.list).then((r) => {
            console.log("downloadList:", r);
            this.$store.commit("setSavePath", r.path);
          });
        }
      });
    },
    openFile() {
      this.$ipc.invoke(ipcApiRoute.openDirectory, { id: this.$store.state.SavePath }).then((res) => {
        console.log("res:", res);
      });
    },
    handleClick(tab, event) {
      this.tableData = [];
      this.page = 1;
      this.getVideo();
    },
    delItem(i) {
      console.log(i);
      del_video({ video_id: i.id }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.getVideo();
      });
    },
    reloadList() {
      const loading = this.$loading({
        lock: true,
        text: "正在获取直播资源",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        item: 10,
        page: this.page,
        type: this.activeName == "first" ? "1" : "2",
      };
      getVideo(params).then((res) => {
        if (res.code == "200") {
          this.total = res.data.page_num;
          this.tableData = res.data.list;
          loading.close();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getVideo() {
      let params = {
        item: 10,
        page: this.page,
        type: this.activeName == "first" ? "1" : "2",
      };
      getVideo(params).then((res) => {
        if (res.code == "200") {
          this.total = res.data.page_num;
          this.tableData = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(e) {
      if (e && e.statusa == 3) {
        this.activeInfo = e;
        this.dialogVisible = true;
      } else {
        this.$message.error("当前视频下载未完成，请刷新状态");
      }
    },
    handleSelectionChange(e) {
      console.log(e, 11);
      this.chooseItem = e;
    },
    downLoadSection() {
      console.log("chooseItem", this.chooseItem);
      this.$ipc.invoke(ipcApiRoute.downloadVideo, this.chooseItem).then((r) => {
        console.log("downloadList:", r);
      });
    },
    edit() {
      this.close();
      this.$emit("edit", this.activeName == "first" ? "2" : "3");
    },
    close() {
      this.$refs.vplayer_start.pause();
      this.activeInfo = {};
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__header {
  background: #fff;
  padding-left: 10px;
}

.btn {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
}

.voxBox {
  .bottom {
    padding: 0.3rem;
    height: 14.2rem;

    img {
      width: 100%;
      height: 9.5rem;
      margin-bottom: 1rem;
    }
  }
}
::v-deep {
  .current-row {
    background-color: #3a6694 !important;
  }
}

// 滚动条的宽度
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}
// 滚动条的滑块
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>
