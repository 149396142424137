import request from '@/utils/request.js'
//获取音频列表
export const getsound = (params) => request.post('/api/sound/get_sound_list', params)
// 获取音色列表
export const gettimbre = (params) => request.post('/api/sound/get_timbre_list', params)
// 提交音频合成
export const craftAudio = (params) => request.post('/api/sound/submit_sound', params)
export const del_sound = (params) => request.post('/api/sound/del_sound', params)
export const upload_mp3 = (params) => request.post('/api/uploads/upload_mp3', params)
export const submits_sound = (params) => request.post('/api/sound/submits_sound', params)


