<template>
  <div class="container">
    <el-row>
      <el-col :span="4">
        <div style="height: 14.61rem; background: #fff">
          <el-menu :default-active="active" class="el-menu-vertical-demo" @select="select">
            <el-menu-item index="1">
              <span slot="title">合成列表</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span slot="title">新增讲解视频</span>
            </el-menu-item>
            <el-menu-item index="3">
              <span slot="title">新增互动视频</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>

      <el-col :span="20">
        <div style="margin-left: 5px">
          <composite-list v-if="active == '1'" @edit="edit"></composite-list>

          <creat-video v-if="active == '2'"></creat-video>

          <add-interaction v-if="active == '3'"></add-interaction>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import compositeList from "./components/compositeList.vue";
import creatVideo from "./components/creatVideo.vue";
import addInteraction from "./components/addInteraction.vue";
import AddliveOption from "../live/components/addliveOption.vue";
import { download } from "@/api/user";

export default {
  components: {
    compositeList,
    creatVideo,
    addInteraction,
    AddliveOption,
  },
  data() {
    return {
      active: "1",
    };
  },
  mounted() {
    this.download();
  },
  methods: {
    select(e) {
      this.active = e;
    },
    edit(e) {
      this.active = e;
      this.$forceUpdate();
    },
    download() {
      // this.$commit('saveListLen',)
    },
  },
};
</script>
<style scoped>
v-deep.is-active {
  color: #fff;
  background: #409eff;
}
</style>
